import { Navigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { useClientStore } from "../../../store/clientStore";
import { useParams, useLocation } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ClientProtectedRoute = ({ children }: any) => {
    const accessToken = useClientStore((state) => state.accessToken);
    const { clientId } = useParams();
    const { pathname } = useLocation();
    if (accessToken === "") {
        const loginPath = `${ROUTES.CLIENTS}/${clientId}${ROUTES.LOGIN}?redirectPath=${pathname}`;
        // user is not authenticated
        return <Navigate to={loginPath} />;
    }
    return children;
};
