import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Divider,
  HStack,
  Heading,
  Icon,
  Input,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createForm } from "../../api/forms";
import CreateFlowField from "../../components/Forms/CreateFormTypeField";
import { ROUTES, theme } from "../../constants";
import {
  CreateFormDto,
  ProviderType,
  ResponseType,
  FormTypeField,
} from "../../types/form";
import Header from "../../components/Head";
import { AiOutlineFileText, AiOutlinePlus } from "react-icons/ai";
import { FaPencilAlt } from "react-icons/fa";

const CreateFlowPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const toast = useToast();

  // variables to edit form information
  const [workflowData, setWorkflowData] = useState<CreateFormDto>();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);

  useEffect(() => {
    const createFormDto: CreateFormDto = state.createFormDto;
    setWorkflowData(createFormDto);
  }, []);

  const isValidField = (
    field: FormTypeField
  ): { success: boolean; errorMsg: string } => {
    if (field.responseType === ResponseType.RADIO) {
      if (field.options && Object.keys(field.options).length === 0) {
        return { success: false, errorMsg: "Radio options are empty." };
      }
    }

    if (field.responseType === ResponseType.NUMBER) {
      if (isNumberRangeInvalid(field)) {
        return { success: false, errorMsg: "Invalid min/max values." };
      }
    }

    return { success: true, errorMsg: "" };
  };

  const isNumberRangeInvalid = (field: FormTypeField) => {
    if (field.responseType !== ResponseType.NUMBER) {
      return;
    }

    if (field.numberOptions?.max && field.numberOptions?.min) {
      return field.numberOptions.max < field.numberOptions.min;
    }

    return false;
  };

  const publishFlow = async () => {
    if (!workflowData) {
      toast({
        title: "Failed to Publish Flow",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    for (const field of workflowData.fields) {
      const { success, errorMsg } = isValidField(field);
      if (!success) {
        toast({
          title: "Failed to Publish Flow",
          description: errorMsg,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    }

    const resp = await createForm(workflowData);
    if (!resp) {
      toast({
        title: "Failed to Publish Flow",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    navigate(ROUTES.FORMS_LIST);
  };

  if (!workflowData) {
    navigate(ROUTES.FORMS_LIST);
    return <></>;
  }

  const updateField = (index: number, field: FormTypeField) => {
    const newFields = [...workflowData.fields];
    newFields[index] = field;
    setWorkflowData({ ...workflowData, fields: newFields });
  };

  const addField = () => {
    const newFields = [...workflowData.fields];
    newFields.push({
      label: "",
      question: "",
      responseType: ResponseType.RADIO,
      required: false,
      isMultiSelect: false,
      key: `q_${newFields.length + 1}`,
    });
    setWorkflowData({ ...workflowData, fields: newFields });
  };

  const removeField = (index: number) => {
    const newFields = [...workflowData.fields];
    newFields.splice(index, 1);
    setWorkflowData({ ...workflowData, fields: newFields });
  };

  return (
      <>
        <Container maxW={"9xl"}>
          <Header description="Create flow page" />

          <VStack align={"start"} spacing={8}>
            <Breadcrumb>
              <BreadcrumbItem>
                <BreadcrumbLink fontWeight={"light"} href={ROUTES.FORMS_LIST}>Forms</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage>
                <Text>Create Form</Text>
              </BreadcrumbItem>
            </Breadcrumb>

            <VStack spacing={2} alignItems={"flex-start"}>
              <HStack>
                <Box w="min" border={"1px dashed"} borderColor={`gray.300`} borderRadius={"md"} p={1}>
                  <AiOutlineFileText size={32} color="gray" />
                </Box>
                {isEditingTitle ? (
                  <Input
                    value={workflowData.name}
                    onChange={(e) => setWorkflowData({ ...workflowData, name: e.target.value })}
                    onBlur={() => setIsEditingTitle(false)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setIsEditingTitle(false);
                      }
                    }}
                    autoFocus
                    fontSize={{ base: "3xl", "2xl": "4xl" }}
                    fontWeight="bold"
                    variant="unstyled"
                    fontFamily={"heading"}
                    minW={{ base: "xl", "2xl": "2xl" }}
                  />
                ) : (
                  <>
                    <Heading
                      fontSize={{ base: "3xl", "2xl": "4xl" }}
                      onDoubleClick={() => setIsEditingTitle(true)}
                    >
                      {workflowData.name}
                    </Heading>
                    <FaPencilAlt
                      size={16}
                      style={{ marginLeft: '8px', cursor: 'pointer' }}
                      onClick={() => setIsEditingTitle(true)}
                    />
                  </>
                )}
              </HStack>
              <HStack>
                {isEditingDescription ? (
                  <Input
                    value={workflowData.description}
                    onChange={(e) => setWorkflowData({ ...workflowData, description: e.target.value })}
                    onBlur={() => setIsEditingDescription(false)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setIsEditingDescription(false);
                      }
                    }}
                    variant="unstyled"
                    autoFocus
                    minW={{ base: "xl", "2xl": "2xl" }}
                    color={`${theme}.800`}
                    fontSize={{ base: "lg", "2xl": "xl" }}
                  />
                ) : (
                  <>
                    <Text 
                      onDoubleClick={() => setIsEditingDescription(true)}
                      color={`${theme}.800`}
                      fontSize={{ base: "lg", "2xl": "xl" }}
                    >
                      {workflowData.description}
                    </Text>
                    <FaPencilAlt
                      size={14}
                      style={{ marginLeft: '4px', cursor: 'pointer' }}
                      onClick={() => setIsEditingDescription(true)}
                    />
                  </>
                )}
              </HStack>
            </VStack>

            <Divider />

            <VStack align={"start"} spacing={4} w="full">
              <Card w={"full"}>
                <CardHeader pb={1}>
                  <Text fontWeight={"medium"} fontSize={{ base: "sm", "2xl": "md" }} color={`${theme}.800`}>
                    Initial message
                  </Text>
                </CardHeader>
                <CardBody pt={2}>
                  <Textarea
                    placeholder="Enter initial message..."
                    mb={3}
                    fontSize={{ base: "md", "2xl": "lg" }}
                    fontWeight="normal"
                    color={`${theme}.700`}
                    value={workflowData?.message}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                      setWorkflowData({ ...workflowData, message: e.target.value })
                    }
                    rows={4}
                    resize="vertical"
                  />
                  <Text
                    fontSize={{ base: "md", "2xl": "lg" }}
                    fontWeight="normal"
                    color={`${theme}.800`}                  
                  >
                    Thanks and have a nice day!
                  </Text>
                </CardBody>
              </Card>

              {workflowData.fields.map((field, index) => (
                <CreateFlowField
                  field={field}
                  index={index}
                  removeField={() => removeField(index)}
                  updateField={(f) => updateField(index, f)}
                />
              ))}

              <Button
                leftIcon={<Icon as={AiOutlinePlus} />} 
                w="full" 
                onClick={addField} 
                variant="ghost"
                color={`${theme}.700`}
              >
                Add new field
              </Button>

              <Card w={"full"}>
                <CardHeader pb={1}>
                  <Text fontWeight={"medium"} fontSize={{ base: "sm", "2xl": "md" }} color={`${theme}.800`}>
                    Success message
                  </Text>
                </CardHeader>
                <CardBody pt={2}>
                  <Textarea
                    placeholder="Enter success message header..."
                    mb={3}
                    fontSize={{ base: "md", "2xl": "lg" }}
                    fontWeight="medium"
                    color={`${theme}.700`}
                    value={workflowData?.postSubmitMsg?.header}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                      setWorkflowData({ 
                        ...workflowData, 
                        postSubmitMsg: {
                          ...workflowData.postSubmitMsg,
                          header: e.target.value
                        }
                      })
                    }
                    rows={1}
                    resize="vertical"
                  />
                  <Textarea
                    placeholder="Enter success message body..."
                    mb={3}
                    fontSize={{ base: "md", "2xl": "lg" }}
                    fontWeight="normal"
                    color={`${theme}.700`}
                    value={workflowData?.postSubmitMsg?.body}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                      setWorkflowData({ 
                        ...workflowData, 
                        postSubmitMsg: {
                          ...workflowData.postSubmitMsg,
                          body: e.target.value
                        }
                      })
                    }
                    rows={3}
                    resize="vertical"
                  />
                </CardBody>
              </Card>
            </VStack>

            <Button w="full" onClick={publishFlow} colorScheme={theme}>
              Save
            </Button>
          </VStack>
        </Container>
      </>
  );
};

export default CreateFlowPage;
