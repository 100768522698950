import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { useState } from "react";
import { updatePatient } from "../../../api/patients";
import { PatientDto } from "../../../types/patient";
import { ProviderType } from "../../../types/form";
import { toTitleCase } from "../../../utils";
import { theme } from "../../../constants";

interface ClientDetailProps {
  patient: PatientDto;
}

const ClientDetail = ({ patient }: ClientDetailProps) => {
  const [initialPatient, setInitialPatient] = useState<PatientDto>(patient);
  const [updatedPatient, setUpdatedPatient] = useState<PatientDto>(patient);
  const toast = useToast();

  const isSame = (a: PatientDto, b: PatientDto) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  const handleSubmit = async () => {
    const updated = await updatePatient(updatedPatient.id, updatedPatient);
    if (updated) {
      setInitialPatient(updated.data);
      setUpdatedPatient(updated.data);
      toast({
        title: "Updated client",
        description: `${updatedPatient.name} has been updated successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Failed to update client",
        description: "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <SimpleGrid
        minChildWidth={"xs"}
        spacing={8}
        py={2}
      >
        <FormControl isRequired>
          <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>Nationality</FormLabel>
          <Input
            value={updatedPatient.nationality}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({
                ...updatedPatient,
                nationality: e.target.value,
              })
            }
            fontSize={{ base: "md", "2xl": "lg" }}
            fontWeight="medium"
            color={`${theme}.900`}
            variant={"flushed"}
          />
        </FormControl>
        <FormControl>
          <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>National ID</FormLabel>
          <Input
            value={updatedPatient?.nationalId}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({
                ...updatedPatient,
                nationalId: e.target.value,
              })
            }
            fontSize={{ base: "md", "2xl": "lg" }}
            fontWeight="medium"
            color={`${theme}.900`}
            variant={"flushed"}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>Date of Birth</FormLabel>
          <SingleDatepicker
            triggerVariant="input"
            name="date-input"
            date={updatedPatient.dob}
            maxDate={new Date()}
            onDateChange={(newDate: Date) => {
              updatedPatient &&
                setUpdatedPatient({
                  ...updatedPatient,
                  dob: new Date(newDate),
                });
            }}
            configs={{
              dateFormat: "d/M/y",
            }}
            propsConfigs={{
              inputProps: {
                fontSize: { base: "md", "2xl": "lg" },
                fontWeight: "medium",
                color: `${theme}.900`,
                width: "full",
                variant: "flushed",
              },
            }}
            usePortal={true}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>Sex</FormLabel>
          <Select
            value={updatedPatient.sex}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({ ...updatedPatient, sex: e.target.value })
            }
            fontSize={{ base: "md", "2xl": "lg" }}
            fontWeight="medium"
            color={`${theme}.900`}
            variant={"flushed"}
          >
            <option value="">Select one</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </Select>
        </FormControl>
        <FormControl isRequired>
          <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>District</FormLabel>
          <Input
            value={updatedPatient.district}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({ ...updatedPatient, district: e.target.value })
            }
            fontSize={{ base: "md", "2xl": "lg" }}
            fontWeight="medium"
            color={`${theme}.900`}
            variant={"flushed"}
          />
        </FormControl>
        <FormControl>
          <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>Email</FormLabel>
          <Input
            value={updatedPatient.email}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({ ...updatedPatient, email: e.target.value })
            }
            fontSize={{ base: "md", "2xl": "lg" }}
            fontWeight="medium"
            color={`${theme}.900`}
            variant={"flushed"}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>Phone Number</FormLabel>
          <Input
            value={updatedPatient.whatsappPhoneNum}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({
                ...updatedPatient,
                whatsappPhoneNum: e.target.value,
              })
            }
            fontSize={{ base: "md", "2xl": "lg" }}
            fontWeight="medium"
            color={`${theme}.900`}
            variant={"flushed"}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>Preferred Channel</FormLabel>
          <Select
            value={updatedPatient.preferredChannel}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({
                ...updatedPatient,
                preferredChannel:
                  ProviderType[e.target.value as keyof typeof ProviderType],
              })
            }
            fontSize={{ base: "md", "2xl": "lg" }}
            fontWeight="medium"
            color={`${theme}.900`}
            variant={"flushed"}
          >
            {Object.keys(ProviderType).map((key) => {
              return (
                <option key={key} value={key}>
                  {toTitleCase(key)}
                </option>
              );
            })}
          </Select>
        </FormControl>
      </SimpleGrid>
      <Button
        w={{ base: "xs", "2xl": "xs" }}
        isDisabled={isSame(initialPatient, updatedPatient)}
        mt={4}
        onClick={handleSubmit}
      >
        Save
      </Button>
    </>
  );
};

export default ClientDetail;
