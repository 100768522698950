import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { createNote, getNotesByPatientId } from "../../../api/patients";
import { getFormDataList } from "../../../api/formData";
import { ROUTES, theme } from "../../../constants";
import {
  PatientActivity,
  PatientActivityType,
  PatientDto,
  PatientNoteDto,
} from "../../../types/patient";
import NotesSection from "../PatientDetail/NotesSection";
import { getGroupDateHeader, groupActivitiesByMonth } from "../utils";
import UploadModal from "../UploadModal";
import { deleteFile } from "../../../api/document";
import { useNavigate } from "react-router-dom";
import { AiOutlineFile, AiOutlineFileText, AiOutlineForm, AiOutlinePlus, AiOutlineTag } from "react-icons/ai";
import { TagPill } from "../../Tags/TagPill";

interface ActivityProps {
  patient: PatientDto;
  refetchPatient: () => Promise<boolean>;
}

const Activity = ({ patient, refetchPatient }: ActivityProps) => {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState<boolean>(true);
  const [notesActivities, setNotesActivities] = useState<PatientActivity[][]>(
    []
  );
  const [tagsActivities, setTagsActivities] = useState<PatientActivity[][]>([]);
  const [responsesActivities, setResponsesActivities] = useState<
    PatientActivity[][]
  >([]);
  const [activities, setActivities] = useState<PatientActivity[][]>([]);
  const [reportActivities, setReportActivities] = useState<PatientActivity[][]>(
    []
  );
  const {
    isOpen: isNotesOpen,
    onOpen: onOpenNotes,
    onClose: onCloseNotes,
  } = useDisclosure();
  const {
    isOpen: isReportOpen,
    onOpen: onOpenReport,
    onClose: onCloseReport,
  } = useDisclosure();

  const toast = useToast();
  const [newNote, setNewNote] = useState<PatientNoteDto>({
    content: "",
    id: "",
    patientId: patient.id,
  });

  const handleDeleteReport = async (docId: string) => {
    const resp = await deleteFile(docId);
    if (resp.status === 200) {
      toast({
        title: "Report deleted",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      await refetchPatient();
      setRefresh(true);
    }
  };

  useEffect(() => {
    if (!refresh) {
      return;
    }

    const getActivities = async () => {
      const allActivities: PatientActivity[] = [];
      const notesResp = await getNotesByPatientId(patient.id);
      const notesActivities: PatientActivity[] = notesResp.data.map((note) => {
        return {
          icon: <AiOutlineFileText />,
          header: (
            <>
              <Text fontFamily={"heading"} fontWeight={"bold"} display={"inline"}>
                Added note
              </Text>
            </>
          ),
          type: PatientActivityType.NOTE,
          date: new Date(note.createdDate || ""),
          content: (
            <Text fontWeight={"normal"} whiteSpace={"pre-line"}>
              {note.content}
            </Text>
          ),
          person: note.staffName || "Unknown staff",
        };
      });
      const responsesResp = await getFormDataList({
        patientIds: [patient.id],
      });
      const responsesActivities: PatientActivity[] = responsesResp.map(
        (response) => {
          const content = (
            <>
              Submitted response to{" "}
              <Link
                color={`${theme}.500`}
                href={`${ROUTES.FORMS_LIST}/${response.formId}`}
              >
                {response.form?.name}
              </Link>
            </>
          );
          return {
            icon: <AiOutlineForm />,
            header: (
              <Text fontFamily={"heading"} fontWeight={"bold"} display={"inline"}>
                Submitted form: {response.form?.name}
              </Text>
            ),
            type: PatientActivityType.RESPONSE,
            date: new Date(response.createdAt),
            content: (
              <Text fontWeight={"normal"} display={"inline"}>
                {content}
              </Text>
            ),
            person: response.patientName
          };
        }
      );
      const tagsActivities: PatientActivity[] =
        patient.tags?.map((tag) => {
          return {
            icon: <AiOutlineTag />,
            header: (
              <>
                <Text fontFamily={"heading"} fontWeight={"bold"} display={"inline"}>
                  Assigned tag:{" "}
                </Text>
                <TagPill tagColor={tag.tagColor} name={tag.name} />
              </>
            ),
            type: PatientActivityType.TAG,
            date: tag.assigned,
            content: (
              <Text fontWeight={"normal"} display={"inline"}>
                {tag.description}
              </Text>
            ),
            person: "Speedback"
          };
        }) || [];
      console.log(patient.documents);
      const reportActivities: PatientActivity[] =
        patient.documents?.map((document) => {
          return {
            icon: <AiOutlineFile />,
            header: (
              <>
                <Text fontFamily={"heading"} fontWeight={"bold"} display={"inline"}>
                  Uploaded Report: {document.label}
                </Text>
              </>
            ),
            type: PatientActivityType.REPORT,
            date: document.createdAt!,
            content: (
              <Flex justifyContent="space-around">
                <Text fontWeight={"normal"}>
                  Uploaded by {document.createdBy}
                </Text>
                <Spacer />
                <Button
                  size={{ base: "md", "2xl": "lg" }}
                  onClick={() => navigate(`${ROUTES.REPORT}/${document.id}`)}
                >
                  Get Data
                </Button>
                <Button
                  size={{ base: "md", "2xl": "lg" }}
                  onClick={() => handleDeleteReport(document.id)}
                  variant="ghost"
                  mr={2}
                  colorScheme="red"
                >
                  Delete
                </Button>
              </Flex>
            ),
            person: document.createdBy || "Unknown staff"
          };
        }) || [];

      allActivities.push(
        ...notesActivities,
        ...(tagsActivities || []),
        ...responsesActivities,
        ...(reportActivities || [])
      );
      allActivities.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );

      const groupedAll = groupActivitiesByMonth(allActivities);
      const groupedNotes = groupActivitiesByMonth(notesActivities);
      const groupedTags = groupActivitiesByMonth(tagsActivities);
      const groupedResponses = groupActivitiesByMonth(responsesActivities);
      const groupedReports = groupActivitiesByMonth(reportActivities);
      setActivities(groupedAll);
      setNotesActivities(groupedNotes);
      setTagsActivities(groupedTags);
      setResponsesActivities(groupedResponses);
      setReportActivities(groupedReports);
    };

    getActivities();
    setRefresh(false);
  }, [patient.id, refresh, patient.tags, patient.documents, patient]);

  const handleNotesSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const created = await createNote(newNote);
    if (created) {
      setNewNote({
        content: "",
        id: "",
        patientId: patient.id,
      });
      setRefresh(true);
      toast({
        title: "Note created",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    onCloseNotes();
  };

  return (
    <div>
      <Modal isOpen={isNotesOpen} onClose={onCloseNotes}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={handleNotesSubmit}>
          <Box bg={"brand"}>
            <ModalHeader color={"light"}>Add Note</ModalHeader>
            <ModalCloseButton color={"light"} />
          </Box>
          <ModalBody pb={6} maxHeight={"70vh"} overflow={"auto"}>
            <Heading size="md" my={6}>
              Create Note
            </Heading>
            <FormControl isRequired mb={4}>
              <FormLabel>Content</FormLabel>
              <Textarea
                value={newNote?.content}
                onChange={(e) =>
                  setNewNote({ ...newNote, content: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>

          <ModalFooter
            borderTop={"1px"}
            borderColor={"gray.200"}
            dropShadow={""}
          >
            <Button mr={3} type="submit">
              Save
            </Button>
            <Button onClick={onCloseNotes} variant={"outline"}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <UploadModal
        isReportOpen={isReportOpen}
        onOpenReport={onOpenReport}
        onCloseReport={onCloseReport}
        patientId={patient.id}
        onSubmit={async () => {
          await refetchPatient();
          setRefresh(true);
        }}
      />

      <Tabs my={4} variant={"solid-rounded"}>
        <HStack justify={"space-between"}>
          <TabList>
            {["All", "Notes", "Tags", "Responses", "Reports"].map((tabName) => (
              <Tab
                key={tabName}
                _selected={{ bg: `${theme}.800`, color: "white", fontWeight: "bold" }}
                bg={`${theme}.50`}
                color="gray.500"
                fontWeight={"regular"}
                mr={4}
                borderRadius="xl"
                fontSize={{ base: "md", "2xl": "lg" }}
              >
                {tabName}
              </Tab>
            ))}
          </TabList>
          <HStack>
            <Button size={{ base: "md", "2xl": "lg" }} leftIcon={<Icon as={AiOutlinePlus} />} onClick={onOpenNotes}>Add Note</Button>
            <Button size={{ base: "md", "2xl": "lg" }} leftIcon={<Icon as={AiOutlinePlus} />} onClick={onOpenReport}>Add Report</Button>
          </HStack>
        </HStack>
        <TabPanels>
          <TabPanel>
            {activities.map((group) => (
              <NotesSection
                activities={group}
                header={getGroupDateHeader(group)}
              />
            ))}
          </TabPanel>
          <TabPanel>
            {notesActivities.map((group) => (
              <NotesSection
                activities={group}
                header={getGroupDateHeader(group)}
              />
            ))}
          </TabPanel>
          <TabPanel>
            {tagsActivities.map((group) => (
              <NotesSection
                activities={group}
                header={getGroupDateHeader(group)}
              />
            ))}
          </TabPanel>
          <TabPanel>
            {responsesActivities.map((group) => (
              <NotesSection
                activities={group}
                header={getGroupDateHeader(group)}
              />
            ))}
          </TabPanel>
          <TabPanel>
            {reportActivities.map((group) => (
              <NotesSection
                activities={group}
                header={getGroupDateHeader(group)}
              />
            ))}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Activity;
