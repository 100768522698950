import {
    VStack,
    Text,
    Icon,
    useToast,
    Spinner,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { ChevronRightDuo } from 'react-coolicons'
import { loginClient, getClientRegisteredAuthMethods, sendResetClientPasscodeMessage } from '../../api/auth';
import { webAuthnLogin } from "../../utils/webauthn";
import { useClientStore } from "../../store/clientStore";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { ClientAuth } from '../../components/ClientAuth';
import { AuthMethod } from '../../types/auth';
import { LinkType } from '../../components/ResendLink';

const makeLabel = (authMethods: AuthMethod[]): string => {
    if (authMethods.length === 0) {
        return "You have not registered yet.";
    }
    const authMethodLabels = authMethods.map(convAuthMethodToLabel);
    if (authMethods.length === 1) {
        return `Login with ${authMethodLabels[0]}`;
    }
    const size = authMethods.length;
    return `Login with ${authMethodLabels.slice(0, size - 1).join(", ")} or ${authMethodLabels[size - 1]}`;
}

const convAuthMethodToLabel = (authMethod: AuthMethod): string => {
    switch (authMethod) {
        case AuthMethod.PASSCODE:
            return "Passcode";
        case AuthMethod.WEBAUTHN:
            return "Biometrics";
        default:
            return "Unknown";
    }
}

const ClientLogin = () => {
    const [passcode, setPasscode] = useState('');
    const navigate = useNavigate();
    const loginHook = useClientStore(state => state.login);
    const [availableAuthMethods, setAvailableAuthMethods] = useState([] as AuthMethod[]);
    const [loading, setLoading] = useState(true);
    const toast = useToast();
    const { clientId } = useParams();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const fetchAuthMethods = async () => {
            const availableAuthMethods = await getClientRegisteredAuthMethods(clientId as string);
            if (availableAuthMethods.error) {
                toast({
                    title: "Could not fetch auth methods",
                    description: availableAuthMethods.error,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                // set to all options if fail
                setAvailableAuthMethods([AuthMethod.PASSCODE, AuthMethod.WEBAUTHN]);
                setLoading(false)
                return;
            }
            setAvailableAuthMethods(availableAuthMethods.authMethods as AuthMethod[]);
            setLoading(false);
        }
        fetchAuthMethods();
    }, [clientId])

    const handleLoginWithPasscode = async () => {
        const resp = await loginClient(clientId as string, passcode);
        if (resp.error) {
            toast({
                title: "Login Failed",
                description: resp.error,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        loginHook({
            clientId: resp.clientId as string,
            accessToken: resp.accessToken as string
        });
        toast({
            title: "Login successful",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
        navigate(searchParams.get("redirectPath") as string);
    }
    const handleLoginWithBiometrics = async () => {
        const resp = await webAuthnLogin(clientId as string);
        if (resp.error) {
            toast({
                title: resp.error.title,
                description: resp.error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        if (!resp.data) {
            toast({
                title: "Unexpected Error",
                description: "BE response missing auth token",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        loginHook({
            clientId: resp.data.clientId as string,
            accessToken: resp.data.accessToken as string
        });
        toast({
            title: "Login successful",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
        navigate(searchParams.get("redirectPath") as string);
    }
    const handleForgotPasscode = async () => {
        const resp = await sendResetClientPasscodeMessage(clientId as string);
        if (resp.error || !resp.success) {
            toast({
                title: "Send Reset Passcode Link Failed",
                description: resp.error,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        toast({
            title: "Success",
            description: "Reset passcode link will be sent to your whatsapp soon",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    }
    return (
        <VStack
            spacing={3}
            alignContent={"center"}
            margin={4}
            textAlign={"center"}
        >
            <Icon
                as={ChevronRightDuo}
                fontSize={'6xl'}
                color={'blue.600'}
            />
            <Text
                fontSize={'3xl'}
                fontWeight={'semibold'}
                width={'3xs'}
            >
                Welcome to Speedback!
            </Text>
            {
                loading ?
                    <Spinner /> :
                    <ClientAuth
                        label={makeLabel(availableAuthMethods)}
                        needConfimation={false}
                        passcode={passcode}
                        setPasscode={setPasscode}
                        passcodeButtonCallback={handleLoginWithPasscode}
                        biometricsButtonCallback={handleLoginWithBiometrics}
                        availableAuthMethods={availableAuthMethods}
                        resendLinkLabel='You have not registered yet or your registered options are not supported on this device.'
                        resendLinkButtonLabel='Send Registration Link'
                        resendLinkType={LinkType.REGISTER}
                        needResetPasscode={true}
                        resetPasscodeCallback={handleForgotPasscode}
                    />
            }
        </VStack>
    );
}

export default ClientLogin;
