import { ReactNode } from "react";
import { CustomPatientTagDto } from "./tag";
import { ProviderType } from "./form";

export type PatientDto = {
  id: string; // unique identifier of the patient
  name: string; // name of the patient
  nationality: string; // current nationality of the patient
  nationalId: string; // national ID of the patient
  dob: Date; // date of birth in patient's timezone
  sex: string; // 'Male' or 'Female'
  district: string; // district of residence
  whatsappPhoneNum: string;
  preferredChannel: ProviderType;
  email?: string;
  lastActivity?: Date; // last activity of the patient
  tags?: CustomPatientTagDto[]; // tags associated with the patient
  documents?: DocumentDto[]; // documents associated with the patient
};

export interface PatientSearchParams {
  name?: string;
  whatsappPhoneNo?: string;
}

export interface PatientQueryParams extends PatientSearchParams {
  page?: number;
  size?: number;
  search?: string;
}

export type PatientNoteDto = {
  id: string;
  content: string;
  patientId: string;
  staffName?: string;
  createdDate?: Date;
  updatedDate?: Date;
};

export enum PatientActivityType {
  NOTE = "NOTE",
  TAG = "TAG",
  RESPONSE = "RESPONSE",
  METRIC = "METRIC",
  REPORT = "REPORT",
}

export type PatientActivity = {
  icon?: ReactNode;
  header: ReactNode;
  person: string;
  type: string;
  date: Date;
  content: ReactNode;
};

export type DocumentDto = {
  id: string;
  label: string;
  url?: string;
  patientId?: string;

  createdBy?: string;
  updatedBy?: string;

  createdAt?: Date;
  updatedAt?: Date;
};

export interface PropertyData {
  unit: string;
  name?: string;
  category?: string;
  value?: string;
}
