import {
  VStack,
  Text,
  Icon,
  useToast
} from '@chakra-ui/react';
import { useState } from 'react';
import { ChevronRightDuo } from 'react-coolicons'
import { resetClientPasscode } from '../../api/auth';
import { useSearchParams } from "react-router-dom";
import { ClientAuth } from '../../components/ClientAuth';
import { AuthMethod } from '../../types/auth';
import { LinkType } from '../../components/ResendLink';


const ClientResetPasscode = () => {
  const [passcode, setPasscode] = useState('');
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const [availableResetMethods, setAvailableResetMethods] = useState([AuthMethod.PASSCODE] as AuthMethod[]);
  
  const handleResetPasscode = async () => {
    const resp = await resetClientPasscode(searchParams.get("resetToken") as string, passcode);
    if (resp.error) {
      let errMsg = resp.error === "Unauthorized" ? "Reset link has expired" : resp.error;
      toast({
        title: "Reset Passcode Failed",
        description: errMsg,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setAvailableResetMethods([]);
      return;
    }
    toast({
      title: resp.message,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  }
  return (
    <VStack
    spacing={3}
    alignContent={"center"}
    margin={4}
    textAlign={"center"}
    >
    <Icon
    as={ChevronRightDuo}
    fontSize={'6xl'}
    color={'blue.700'}
    />
    <Text
    fontSize={'3xl'}
    fontWeight={'semibold'}
    width={'3xs'}
    >
    Welcome to Speedback!
    </Text>
    <ClientAuth
    label={"Reset Passcode"}
    needConfimation
    passcode={passcode}
    setPasscode={setPasscode}
    passcodeButtonCallback={handleResetPasscode}
    biometricsButtonCallback={() => { }}
    availableAuthMethods={availableResetMethods}
    resendLinkLabel='Forgot password link has expired.'
    resendLinkButtonLabel='Resend Link'
    resendLinkType={LinkType.RESET_PASSCODE}
    needResetPasscode={false}
    />
    </VStack>
  );
}

export default ClientResetPasscode;
