import {
    VStack,
    Text,
    Icon,
    useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { ChevronRightDuo } from 'react-coolicons'
import { registerClient, sendRegisterClientMessage } from '../../api/auth';
import { webAuthnRegister } from "../../utils/webauthn";
import { useClientStore } from "../../store/clientStore";
import { useSearchParams, useNavigate } from "react-router-dom";
import { ClientAuth } from '../../components/ClientAuth';
import { AuthMethod } from '../../types/auth';
import { LinkType } from '../../components/ResendLink';


const ClientRegister = () => {
    const [passcode, setPasscode] = useState('');
    const loginHook = useClientStore(state => state.login);
    const toast = useToast();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [availableAuthMethods, setAvailableAuthMethods] = useState([
        AuthMethod.PASSCODE,
        AuthMethod.WEBAUTHN
    ] as AuthMethod[]);

    const handleRegisterWithPasscode = async () => {
        const resp = await registerClient(searchParams.get("registerToken") as string, passcode);
        if (resp.error) {
            let errMsg = resp.error
            if (resp.error === "Unauthorized") {
                errMsg = "Registeration link has expired";
                // webauthn and passcode share the same register token, so if one fails, the other should too
                setAvailableAuthMethods([]);
            }
            toast({
                title: "Registeration Failed",
                description: errMsg,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        loginHook({
            clientId: resp.clientId as string,
            accessToken: resp.accessToken as string
        });
        toast({
            title: "Registeration successful",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
        navigate(searchParams.get("redirectPath") as string);
    }
    const handleRegisterWithBiometrics = async () => {
        const resp = await webAuthnRegister(searchParams.get("registerToken") as string);
        if (resp.error) {
            let errMsg = resp.error.message
            if (resp.error.message === "Unauthorized") {
                errMsg = "Registeration link has expired";
                // webauthn and passcode share the same register token, so if one fails, the other should too
                setAvailableAuthMethods([]);
            }
            toast({
                title: resp.error.title,
                description: resp.error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        if (!resp.data) {
            toast({
                title: "Unexpected Error",
                description: "BE response missing auth token",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        loginHook({
            clientId: resp.data.clientId as string,
            accessToken: resp.data.accessToken as string
        });
        toast({
            title: "Registeration with biometrics successful",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
        navigate(searchParams.get("redirectPath") as string);
    }
    return (
        <VStack
            spacing={3}
            alignContent={"center"}
            margin={4}
            textAlign={"center"}
        >
            <Icon
                as={ChevronRightDuo}
                fontSize={'6xl'}
                color={'blue.700'}
            />
            <Text
                fontSize={'3xl'}
                fontWeight={'semibold'}
                width={'3xs'}
            >
                Welcome to Speedback!
            </Text>
            <ClientAuth
                label={"Register with Passcode or Biometrics"}
                needConfimation
                passcode={passcode}
                setPasscode={setPasscode}
                passcodeButtonCallback={handleRegisterWithPasscode}
                biometricsButtonCallback={handleRegisterWithBiometrics}
                availableAuthMethods={availableAuthMethods}
                resendLinkLabel='Registeration link has expired.'
                resendLinkButtonLabel='Resend Link'
                resendLinkType={LinkType.REGISTER}
                needResetPasscode={false}
            />
        </VStack>
    );
}

export default ClientRegister;
