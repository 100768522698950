import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  Center,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Select,
  Spacer,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaDownload, FaFacebookMessenger, FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { Frequency, RRule } from "rrule";
import { getAllPatients } from "../../api/patients";
import { getFormDataList } from "../../api/formData";
import {
  deprecateFormById,
  getFormById,
  sendForm
} from "../../api/forms";
import DeprecateFlowModal from "../../components/Forms/DeprecateModal";
import Header from "../../components/Head";
import { messagingApps, ROUTES, theme } from "../../constants";
import { dayOptions, DEFAULT_SELECTED_HOUR, formatHourStr, frequencyOptions, hours } from "../../constants/time";
import { PatientDto } from "../../types/patient";
import {
  ProviderType,
  ResponseType,
  FormDataResp,
  FormDto,
  CreateFormSendDto
} from "../../types/form";
import {
  exportToCSV,
} from "../../utils";
import { AiOutlineFileText, AiOutlineTeam } from "react-icons/ai";
import FormField from "../../components/Form/FormField";
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList, AutoCompleteTag } from "@choc-ui/chakra-autocomplete";

const ViewFormPage = () => {
  const { flowId } = useParams();
  const {
    isOpen: isDeprecateModalOpen,
    onOpen: openDeprecateModal,
    onClose: onCloseDeprecateFlowModal,
  } = useDisclosure();
  const [responses, setResponses] = useState<FormDataResp[]>([]);
  const [form, setForm] = useState<FormDto>();
  const toast = useToast();
  const navigate = useNavigate();
  const [isRefreshed, setIsRefreshed] = useState(false);

  // variables needed for sending form
  const [formSendData, setFormSendData] = useState<CreateFormSendDto>({ 
    formId: '',
    channel: messagingApps[0],
    patientIds: [],
    schedule: undefined,
    remainingSends: undefined
  });
    const [frequency, setFrequency] = useState<Frequency>(Frequency.DAILY);
  const [selectedDay, setSelectedDay] = useState<number>(RRule.MO.weekday);
  const [selectedHour, setSelectedHour] = useState<number>(
    DEFAULT_SELECTED_HOUR
  );
  const [recurring, setRecurring] = useState<boolean>(false);
  const [selectedRadio, setSelectedRadio] = useState<string>('1'); // Track selected radio value
  const [numberValue, setNumberValue] = useState<number>(3); // Track number input value

  // load all patients for sending forms to
  const [patients, setPatients] = useState<PatientDto[]>([]);

  // on top of the normal columns, we have 3 additional columns (#, patient name and date recorded)
  const NUM_ADDITIONAL_COLS = 3;

  useEffect(() => {
    async function getPatients() {
      const patientResp = await getAllPatients();
      setPatients(patientResp.data.data ?? []);
    }

    getPatients();

    if (isRefreshed) {
      return;
    }

    async function getPageData() {
      console.log(flowId);
      if (!flowId) {
        return;
      }

      const form = await getFormById(flowId);
      if (!form) {
        return;
      }

      setForm(form);
      setFormSendData({ ...formSendData, formId: form.id })

      const responses = await getFormDataList({
        formIds: [flowId],
      });
      if (!responses) {
        return;
      }

      setResponses(responses);
    }

    getPageData();

    setIsRefreshed(true);
  }, [isRefreshed]);

  useEffect(() => {
    const now = new Date();
    const nextOccurrence = new Date(now);
    nextOccurrence.setDate(
      now.getDate() + ((7 + selectedDay - now.getDay()) % 7)
    );
    nextOccurrence.setHours(selectedHour, 0, 0);
    const schedule = new RRule({
      freq: frequency,
      wkst: nextOccurrence.getUTCDay(),
      byhour: nextOccurrence.getUTCHours(),
      byminute: nextOccurrence.getUTCMinutes(),
      bysecond: nextOccurrence.getUTCSeconds(),
    }).toString();

    setFormSendData({
      ...formSendData,
      schedule: recurring ? schedule : undefined,
    })
  }, [selectedDay, selectedHour, recurring])

  if (!flowId) {
    navigate(ROUTES.NOT_FOUND);
    return <></>;
  }

  if (!form) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  // handle radio change
  const handleRadioChange = (value: string) => {
    let remainingSends;
    setSelectedRadio(value);
    if (value === '1') {
      remainingSends = undefined;
    } else if (value === '2') {
      remainingSends = numberValue;
    }
    setFormSendData({
      ...formSendData,
      remainingSends
    })
  }

  // Handle number input change
  const handleNumberChange = (value: string) => {
    const numericValue = parseInt(value);
    setNumberValue(numericValue);
    if (selectedRadio === '2') {
      setFormSendData({
        ...formSendData,
        remainingSends: numberValue
      })
    }
  };

  const deprecateFlow = async () => {
    const resp = await deprecateFormById(flowId);
    if (resp) {
      setIsRefreshed(false);
      toast({
        title: "Successfully deprecated flow",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate(ROUTES.FORMS_LIST);
    } else {
      toast({
        title: "Failed to deprecate flow",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSend = () => {
    try {
      if (!formSendData) {
        throw new Error('Form send data not found.');
      }

      // must select at least one patient
      if (formSendData.patientIds.length <= 0) {
        throw new Error('Please select at least one patient.');
      }

      sendForm(formSendData);
      toast({
        title: "Success!",
        description: "Form sent successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: "Error sending form!",
        description: error?.message || error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleExportToCSV = () => {
    const headers = [
      "#",
      "Client Name",
      ...(form?.fields.map((field) => field.label) ?? []),
      "Date Recorded",
    ];

    const dataRows = responses.map((response, idx) => [
      `${responses.length - idx}`,
      response.patientName,
      ...form?.fields.map(
        (field, fieldIdx) => response.rawData[`q_${fieldIdx + 1}`] ?? ""
      ),
      new Date(response.createdAt).toLocaleDateString("en-GB"),
    ]);

    exportToCSV(dataRows, headers, `${form.name}_export`);
  };

  return (
    <>
      <Container maxW="9xl">
        <Header description="View flow page" />

        <DeprecateFlowModal
          isOpen={isDeprecateModalOpen}
          onClose={onCloseDeprecateFlowModal}
          onConfirmDeprecate={deprecateFlow}
        />

        <VStack align={"start"} spacing={8}>
          <Breadcrumb>
            <BreadcrumbItem>
              <BreadcrumbLink fontWeight={"light"} href={ROUTES.FORMS_LIST}>Forms</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <Text>{form.name}</Text>
            </BreadcrumbItem>
          </Breadcrumb>
          
          <VStack w="full" spacing={2} alignItems={"flex-start"}>
            <HStack w="full">
              <Box w="min" bg={`gray.100`} borderRadius={"md"} p={1}>
                <AiOutlineFileText size={32} color="gray" />
              </Box>
              <Heading fontSize={{ base: "3xl", "2xl": "4xl" }}>
                {form.name}
              </Heading>
              <Spacer />
              <Button onClick={openDeprecateModal}>
                Deprecate Flow
              </Button>
            </HStack>
            <HStack>
            <Text color={`${theme}.800`} fontSize={{ base: "lg", "2xl": "xl" }}>
              {form.description}
            </Text>
            </HStack>
          </VStack>

          <Tabs isFitted size={{ base: "md", "2xl": "lg" }} w="full">
            <TabList>
              <Tab>Questions</Tab>
              <Tab>Responses</Tab>
              <Tab>Send Form</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Text size={{ base: "md", "2xl": "lg" }} mb={4}>
                  {form.message}
                </Text>
                <VStack spacing={3} w="full" align={"flex-start"}>
                  {form.fields.map((field, index) => {
                    return (
                      <Card w="full">
                        <CardBody>
                          <FormField
                            key={index}
                            questionNum={index + 1}
                            field={field}
                            handleInputChange={() => void 0}
                          />
                        </CardBody>
                      </Card>
                    );
                  })}
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack mt={2} spacing={6} w="full" align={"flex-start"}>
                  <Flex justifyContent={"space-between"} w="full">
                    <Heading fontWeight={"normal"} size={{ base: "md", "2xl": "lg" }}>
                      {responses.length} Responses
                    </Heading>

                    <Button
                      aria-label="Export to CSV"
                      leftIcon={<FaDownload />}
                      onClick={handleExportToCSV}
                    >
                      Export to CSV
                    </Button>
                  </Flex>
                  <Box border="2px solid" borderRadius={"md"} borderColor={"gray.200"} p={4} w="full">
                    <Table variant="unstyled" size={{ base: "md", "2xl": "lg" }} fontSize={{ base: "md", "2xl": "lg" }}>
                      <Thead>
                        <Tr borderBottom={"2px solid"} borderColor={"gray.200"}>
                          <Th>
                            <Text>#</Text>
                          </Th>
                          <Th>
                            <Text>Client Name</Text>
                          </Th>
                          {form?.fields.map((field) => {
                            return (
                              <Th
                                isNumeric={field.responseType === ResponseType.NUMBER}
                                key={field.key}
                              >
                                <Text>{field.label}</Text>
                              </Th>
                            );
                          })}
                          <Th>
                            <Text>date recorded</Text>
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {responses.length > 0 &&
                          responses.map((response, idx) => {
                            return (
                              <Tr key={response.id}>
                                <Td>{idx + 1}</Td>
                                <Td cursor={"pointer"}>{response.patientName}</Td>
                                {form?.fields.map((field, fieldIdx) => {
                                  return (
                                    <Td>{response.rawData[`q_${fieldIdx + 1}`] ?? ""}</Td>
                                  );
                                })}
                                <Td>
                                  {new Date(response.createdAt).toLocaleDateString(
                                    "en-GB"
                                  )}
                                </Td>
                              </Tr>
                            );
                          })}
                        {responses.length === 0 && (
                          <Tr>
                            <Td
                              textAlign={"center"}
                              colSpan={
                                (form?.fields.length ?? 0) + NUM_ADDITIONAL_COLS
                              }
                            >
                              No responses yet
                            </Td>
                          </Tr>
                        )}
                      </Tbody>
                    </Table>
                  </Box>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack spacing={8} align="flex-start">
                  <VStack spacing={4} align={"flex-start"}>
                    <Text 
                      color={`${theme}.800`} 
                      fontSize={{ base: "md", "2xl": "lg" }}
                      fontWeight={"medium"}
                    >
                      Send form to
                    </Text>
                    <Card w={"full"} p={3}>
                      <HStack spacing={4}>
                        <Box w="min" bg={`gray.100`} borderRadius={"md"} p={1}>
                          <AiOutlineTeam size={32} />
                        </Box>
                        <FormControl>
                          <AutoComplete
                            multiple
                            rollNavigation
                            onChange={(vals) =>
                              setFormSendData({
                                ...formSendData,
                                patientIds: patients.filter((cl) => vals.includes(cl.name)).map((cl) => cl.id)
                              })
                            }
                          >
                            <AutoCompleteInput
                              w={{ base: "xl", "2xl": "2xl" }}
                              placeholder="Select patients"
                              autoFocus
                              fontSize={{ base: "md", "2xl": "lg" }}
                            >
                              {({ tags }) =>
                                tags.map((tag, tid) => (
                                  <AutoCompleteTag
                                    key={tid}
                                    label={tag.label}
                                    onRemove={tag.onRemove}
                                  />
                                ))
                              }
                            </AutoCompleteInput>
                            <AutoCompleteList>
                              {patients.length > 0 &&
                                patients.map((patient, idx) => (
                                  <AutoCompleteItem
                                    key={`option-${idx}`}
                                    value={patient.name}
                                  >
                                    {patient.name}
                                  </AutoCompleteItem>
                                ))}
                            </AutoCompleteList>
                          </AutoComplete>
                        </FormControl>
                      </HStack>
                    </Card>
                  </VStack>
                  
                  <VStack align={"flex-start"}>
                    <Text 
                      color={`${theme}.800`} 
                      fontSize={{ base: "md", "2xl": "lg" }}
                      fontWeight={"medium"}
                    >
                      Via
                    </Text>
                    <RadioGroup
                      onChange={(value) =>
                        setFormSendData({ ...formSendData, channel: value as ProviderType })
                      }
                      value={formSendData.channel}
                    >
                      <VStack alignItems="flex-start" spacing={3}>
                        <Radio
                          key={ProviderType.WHATSAPP}
                          value={ProviderType.WHATSAPP}
                        >
                          <HStack>
                            <Text color={`${theme}.800`} fontSize={{ base: "md", "2xl": "lg" }}>Whatsapp</Text>
                            <FaWhatsapp />
                          </HStack>
                        </Radio>
                        <Tooltip
                          label="Contact Speedback support to activate this channel"
                          shouldWrapChildren
                        >
                          <Radio
                            isDisabled
                            key={ProviderType.FB_MESSENGER}
                            value={ProviderType.FB_MESSENGER}
                          >
                            <HStack>
                              <Text color={`${theme}.800`} fontSize={{ base: "md", "2xl": "lg" }}>Facebook Messenger</Text>
                              <FaFacebookMessenger />
                            </HStack>
                          </Radio>
                        </Tooltip>
                        <Tooltip
                          label="Contact Speedback support to activate this channel"
                          shouldWrapChildren
                        >
                          <Radio
                            isDisabled
                            key={ProviderType.TELEGRAM}
                            value={ProviderType.TELEGRAM}
                          >
                            <HStack>
                              <Text color={`${theme}.800`} fontSize={{ base: "md", "2xl": "lg" }}>Telegram</Text>
                              <FaTelegramPlane />
                            </HStack>
                          </Radio>
                        </Tooltip>
                        <Text color={`${theme}.800`} fontSize={{ base: "sm", "2xl": "md" }} fontWeight={"light"}>Notification charges apply</Text>
                      </VStack>
                    </RadioGroup>
                  </VStack>
                  
                  <VStack align={"flex-start"}>
                    <Checkbox onChange={(e) => setRecurring(e.target.checked)}>
                      Repeat
                    </Checkbox>
                    {recurring && (
                      <>
                        <Text 
                          color={`${theme}.800`} 
                          fontSize={{ base: "md", "2xl": "lg" }}
                          fontWeight={"medium"}
                          mb={2}
                        >
                          Schedule (Following timezone: Asia/Singapore, GMT+8)
                        </Text>
                        <HStack spacing={20} alignItems={"center"}>
                          <Text width={"100px"} color={`${theme}.800`} fontSize={{ base: "md", "2xl": "lg" }}>Run every:</Text>
                          <Select
                            width={"300px"}
                            value={frequency}
                            defaultValue={RRule.DAILY}
                            onChange={(e) => setFrequency(parseInt(e.target.value))}
                            color={`${theme}.800`} 
                            fontSize={{ base: "md", "2xl": "lg" }} 
                            fontWeight={"light"}
                          >
                            {frequencyOptions.map((freq) => (
                              <option key={freq.value} value={freq.value}>
                                {freq.name}
                              </option>
                            ))}
                          </Select>
                        </HStack>
                        {frequency === Frequency.WEEKLY && (
                          <HStack spacing={20} alignItems={"flex-start"}>
                            <Text width={"100px"} color={`${theme}.800`} fontSize={{ base: "md", "2xl": "lg" }}>On:</Text>
                            <Select
                              width={"300px"}
                              value={selectedDay}
                              defaultValue={RRule.MO.weekday}
                              onChange={(e) => setSelectedDay(parseInt(e.target.value))}
                              color={`${theme}.800`} 
                              fontSize={{ base: "md", "2xl": "lg" }} 
                              fontWeight={"light"}
                            >
                              {dayOptions.map((day) => (
                                <option key={day.value.weekday} value={day.value.weekday}>
                                  {day.name}
                                </option>
                              ))}
                            </Select>
                          </HStack>
                        )}
                        <HStack spacing={20} alignItems={"flex-start"}>
                          <Text width={"100px"} color={`${theme}.800`} fontSize={{ base: "md", "2xl": "lg" }}>At:</Text>
                          <Select
                            width={"300px"}
                            value={selectedHour}
                            defaultValue={DEFAULT_SELECTED_HOUR}
                            onChange={(e) => setSelectedHour(parseInt(e.target.value))}
                            color={`${theme}.800`} 
                            fontSize={{ base: "md", "2xl": "lg" }} 
                            fontWeight={"light"}
                          >
                            {hours.map((hour) => (
                              <option key={hour} value={hour}>
                                {formatHourStr(hour)}
                              </option>
                            ))}
                          </Select>
                        </HStack>
                        <VStack align={"flex-start"}>
                          <Text 
                            color={`${theme}.800`} 
                            fontSize={{ base: "md", "2xl": "lg" }}
                            fontWeight={"medium"}
                            mb={2}
                          >
                            Ends
                          </Text>
                          <RadioGroup defaultValue="1" onChange={handleRadioChange}>
                            <VStack align={"flex-start"}>
                              <Radio value='1'>Never</Radio>
                              <Radio value='2'>
                                <HStack>
                                  <Text width={"100px"} color={`${theme}.800`} fontSize={{ base: "md", "2xl": "lg" }}>After:</Text>
                                  <NumberInput
                                    size={{ base: "sm", "2xl": "md" }}
                                    value={numberValue}
                                    onChange={handleNumberChange}
                                    min={1}
                                  >
                                    <NumberInputField
                                      fontSize={{ base: "md", "2xl": "lg" }}
                                      fontWeight="light"
                                      color={`${theme}.800`}
                                    />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                  <Text width={"100px"} color={`${theme}.800`} fontSize={{ base: "md", "2xl": "lg" }}>occurence(s)</Text>
                                </HStack>
                              </Radio>
                            </VStack>
                          </RadioGroup>
                        </VStack>
                      </>
                    )}
                  </VStack>

                  <Button
                    onClick={handleSend}
                    size={{ base: "md", "2xl": "lg" }}
                    w={"xl"}
                  >
                    Send
                  </Button>
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </Container>
    </>
  );
};

export default ViewFormPage;
