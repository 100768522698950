import { saveAs } from "file-saver";
import { ProviderType, FormStatus } from "../types/form";
import Papa from "papaparse";

export function toTitleCase(str: string | undefined) {
  if (!str) return "";
  return str.replaceAll("_", " ").replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const getStatusColor = (status: FormStatus) => {
  switch (status) {
    case FormStatus.DEPRECATED:
      return "red";
    case FormStatus.PUBLISHED:
      return "green";
    default:
      return "gray";
  }
};

export const getProviderFriendlyName = (provider: ProviderType) => {
  switch (provider) {
    case ProviderType.FB_MESSENGER:
      return "Facebook Messenger";
    case ProviderType.WHATSAPP:
      return "WhatsApp";
    default:
      return "Unknown";
  }
};

export const getChannelColor = (channel: ProviderType) => {
  switch (channel) {
    case ProviderType.FB_MESSENGER:
      return "purple";
    case ProviderType.WHATSAPP:
      return "green";
    default:
      return "gray";
  }
};

/**
 * Exports data to a CSV file.
 *
 * @param {Array.<Array.<string>>} dataRows Array of data rows, where each row is an array of strings.
 * @param {Array.<string>} headers Array of header strings.
 * @param {string} fileName Name of the file to save.
 */
export const exportToCSV = (
  dataRows: Array<Array<string>>,
  headers: Array<string>,
  fileName: string
) => {
  const csvRows = [
    headers.map((header) => escapeCSVField(header)).join(","), // Prepare header row
    ...dataRows.map((row) =>
      row.map((field) => escapeCSVField(field)).join(",")
    ), // Prepare data rows
  ];
  const csvString = csvRows.join("\n");
  const blob = new Blob([csvString], { type: "text/csv" });
  saveAs(blob, fileName);
};

export const validateCSVForBulkUpload = (file: File): Promise<boolean> => {
  return new Promise((resolve) => {
    Papa.parse(file, {
      header: true,
      preview: 1, // We only need to check the header row
      complete: (results) => {
        const headers = results.meta.fields || [];
        const isValid =
          headers.length === expectedColumnsForBulkPatientUpload.length &&
          expectedColumnsForBulkPatientUpload.every((col) =>
            headers.includes(col)
          );
        resolve(isValid);
      },
      error: () => {
        resolve(false);
      },
    });
  });
};

function escapeCSVField(field: string) {
  if (field === null || field === undefined) {
    return '""'; // Represent null or undefined as empty string in CSV
  }

  let fieldStr = String(field); // Convert non-string fields to string

  // Escape double quotes by doubling them
  fieldStr = fieldStr.replace(/"/g, '""');

  // Enclose field in double quotes if it contains commas, double quotes, or newlines
  if (
    fieldStr.includes(",") ||
    fieldStr.includes("\n") ||
    fieldStr.includes("\r") ||
    fieldStr.includes('"')
  ) {
    fieldStr = `"${fieldStr}"`;
  }

  return fieldStr;
}

export function deepEquals<T>(obj1: T, obj2: T): boolean {
  // If both objects are identical, return true
  if (obj1 === obj2) {
    return true;
  }

  // If either object is null or not an object, return false
  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1) as (keyof T)[];
  const keys2 = Object.keys(obj2) as (keyof T)[];

  // If the number of keys differs, objects are not equal
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check each key and value recursively
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEquals(obj1[key], obj2[key])) {
      return false;
    }
  }

  // Objects are deeply equal
  return true;
}

export const expectedColumnsForBulkPatientUpload = [
  "name",
  "nationality",
  "nationalId",
  "dob",
  "sex",
  "district",
  "email",
  "preferredChannel",
  "whatsappPhoneNum",
];

export const downloadClientTemplate = () => {
  const headers = expectedColumnsForBulkPatientUpload;
  const exampleData = [
    "Max Tan",
    "Singaporean",
    "123456789",
    "01/01/1980",
    "Male",
    "Downtown",
    "max.tan@example.com",
    "Email",
    "+1234567890",
  ];
  const csvContent = [headers.join(","), exampleData.join(",")].join("\n");
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, "speedback_bulk_upload_template.csv");
};
