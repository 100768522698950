import { PatientTagDto } from "../types/tag";
import axios from "./axios/staffAuth";

const API_URL = process.env.REACT_APP_API_URL || "";

// Queries

// Fetches all tags
export const getAllPatientTagsByTagId = (
  page: number,
  size: number,
  tagId: string
) => {
  return axios.get<{ data: PatientTagDto[]; total: number }>(
    `${API_URL}/patient-tags/${tagId}`,
    {
      params: {
        page,
        size,
      },
    }
  );
};
