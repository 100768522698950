import { PatientMetricDto } from "../types/form";
import axios from "./axios/staffAuth";

// Using React environment variable for API URL
const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000";

async function createPatientMetric(
  dto: PatientMetricDto
): Promise<PatientMetricDto> {
  const res = await axios.post<PatientMetricDto>(
    `${API_URL}/patientMetric`,
    dto
  );
  return res.data;
}

async function createAllPatientMetrics(
  dtos: PatientMetricDto[]
): Promise<PatientMetricDto[]> {
  const res = await axios.post<PatientMetricDto[]>(
    `${API_URL}/patientMetric/all`,
    dtos
  );
  return res.data;
}

async function updatePatientMetric(
  dto: PatientMetricDto
): Promise<PatientMetricDto> {
  const res = await axios.put<PatientMetricDto>(
    `${API_URL}/patientMetric`,
    dto
  );
  return res.data;
}

async function getPatientMetricList(req?: {
  formIds?: string[];
  patientIds?: string[];
}): Promise<PatientMetricDto[] | null> {
  const res = await axios.get<PatientMetricDto[]>(
    `${API_URL}/patientMetric/list`,
    {
      params: req,
    }
  );
  return res.data;
}

async function searchPatientMetrics(req: {
  patientId: string;
  search: string;
}): Promise<PatientMetricDto[] | null> {
  const res = await axios.get<PatientMetricDto[]>(
    `${API_URL}/patientMetric/search`,
    {
      params: req,
    }
  );

  return res.data;
}

export {
  createPatientMetric,
  createAllPatientMetrics,
  getPatientMetricList,
  searchPatientMetrics,
  updatePatientMetric,
};
