import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Link,
  Stack,
  Tab,
  TabList,
  Table,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  Divider,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ChartData } from "chart.js";
import { useEffect, useState } from "react";
import "react-calendar-heatmap/dist/styles.css";
import { FaChevronLeft, FaDownload } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { getPatientById } from "../../api/patients";
import { getFormDataList } from "../../api/formData";
import { getFormById } from "../../api/forms";
import Header from "../../components/Head";
import LineChartComponent from "../../components/Patients/LineChartComponent";
import { theme } from "../../constants";
import { TimePeriod, getFirstDate } from "../../constants/time";
import { PatientDto } from "../../types/patient";
import {
  ResponseType,
  FormDataDto,
  FormDto,
} from "../../types/form";
import { deepEquals, exportToCSV } from "../../utils";
import AddRecordModal from "./AddRecordModal";
import "./index.css";

interface ClientResponseViewProps {
  isAdminView?: boolean;
}

const ClientResponseView = ({ isAdminView }: ClientResponseViewProps) => {
  const [refresh, setRefresh] = useState<boolean>(true);
  const [patient, setPatient] = useState<PatientDto>();
  const toast = useToast();
  const [datum, setDatum] = useState<FormDataDto[]>([]);
  const [displayedDatum, setDisplayedDatum] = useState<FormDataDto[]>([]);
  const [form, setForm] = useState<FormDto>();
  const [selectedTimePeriodTab, setSelectedTimePeriodTab] = useState<number>(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { clientId, flowId } = useParams();
  const navigate = useNavigate();
  const DATE_COL_IDX = 0;

  useEffect(() => {
    if (clientId && flowId && refresh) {
      fetchClientById(clientId);
      fetchForm(flowId);
      fetchFormData(clientId, flowId);
      setRefresh(false);
    }
  }, [clientId, flowId, refresh]);

  const sortedDatum = [...datum].sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );

  useEffect(() => {
    // Filter the data
    const filteredData = filterDatumByTimePeriod(
      sortedDatum,
      timePeriodOptions[selectedTimePeriodTab]
    );

    if (!deepEquals(filteredData, displayedDatum)) {
      setDisplayedDatum(filteredData);
    }
  }, [sortedDatum, selectedTimePeriodTab]);

  const timePeriodOptions = [
    TimePeriod.PAST_WEEK,
    TimePeriod.PAST_MONTH,
    TimePeriod.ALL_TIME,
  ];

  const filterDatumByTimePeriod = (
    datum: FormDataDto[],
    timePeriod: TimePeriod
  ): FormDataDto[] => {
    const firstDate = getFirstDate(timePeriod);
    return datum.filter((d) => new Date(d.createdAt) >= firstDate);
  };

  const fetchClientById = async (id: string) => {
    try {
      const resp = await getPatientById(id);
      if (resp.data) {
        setPatient(resp.data);
      }
    } catch (error: any) {
      toast({
        title: "Failed to fetch patient",
        description: error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

  };

  const fetchFormData = async (patientId: string, formId: string) => {
    try {
      const resp = await getFormDataList({
        patientIds: [patientId],
        formIds: [formId],
      });
      if (resp) {
        // ensure reverse chronological order
        resp.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setDatum(resp);
      }
    } catch (error: any) {
      toast({
        title: "Failed to fetch form data",
        description: error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchForm = async (formId: string) => {
    try {
      const resp = await getFormById(formId);
      if (resp) {
        setForm(resp);
      }
    } catch (error: any) {
      toast({
        title: "Failed to fetch form",
        description: error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    };
  }

  const datumKeys = form?.fields.map((field) => field.key);
  const rows: string[][] = [...displayedDatum].reverse().map((d) => {
    const row = [
      new Date(d.createdAt).toDateString(),
      ...(datumKeys?.map((key) => d.rawData[key]) ?? []),
    ];
    return row;
  });

  const getGraphData = (key: string): ChartData => {
    return {
      labels:
        sortedDatum.map((d) => {
          const date = new Date(d.createdAt);
          return `${date.getDate().toString().padStart(2, "0")}/${(
            date.getMonth() + 1
          )
            .toString()
            .padStart(2, "0")}`;
        }) ?? [],

      datasets:
        form?.fields
          .filter((f) => f.key === key)
          .map((field, idx) => {
            return {
              label: field.label,
              data: sortedDatum.map((d) => parseInt(d.rawData[field.key])),
              fill: false,
              borderColor: `rgb(${idx * 200}, 99, 132)`,
              tension: 0.1,
            };
          }) ?? [],
    };
  };

  const handleCloseAddRecordModal = () => {
    setRefresh(true);
    onClose();
  };
  return (
    <>
      <Header description="View your data" />
      <Container maxW={"5xl"} my={5} mx={3}>
        {isAdminView && (
          <Link
            onClick={() => navigate(-1)}
            display="flex"
            alignItems="center"
            mb={4}
            color={"gray"}
          >
            <FaChevronLeft />
            <Text ml={1}>Back</Text>
          </Link>
        )}
        <VStack align={"left"} mb={5}>
          <Heading mb={4}>{form?.name}</Heading>
          {isAdminView && <Stack direction={["column", "row"]} spacing={5} mb={5}>
            <Text color={"gray"}>{datum.length} responses</Text>
            {datum.length > 0 && (
              <Text color={"gray"}>
                Last responded on: {new Date(datum[0].createdAt).toDateString()}
              </Text>
            )}
          </Stack>}
        </VStack>
        {isAdminView && <Heading mb={4} size="md">
          {patient?.name}
        </Heading>}
        <Tabs
          index={selectedTimePeriodTab}
          onChange={setSelectedTimePeriodTab}
          variant="soft-rounded"
          colorScheme="blue"
          mb={1}
        >
          <TabList>
            <Tab>7 Day</Tab>
            <Tab>Month</Tab>
            <Tab>All Time</Tab>
          </TabList>
        </Tabs>
        <Divider mb={8}></Divider>
        <Flex wrap={"wrap"}>
          {form?.fields
            .filter((f) => f.responseType === ResponseType.PROPERTY)
            .map((f) => {
              return (
                <Box mb={8} w={400}>
                  <LineChartComponent data={getGraphData(f.key)} />
                </Box>
              );
            })}
        </Flex>

        <Flex justifyContent={"space-between"}>
          <Heading size="sm" mb={4}>
            Responses
          </Heading>
          {isAdminView && (
            <Box>
              {form && (
                <>
                  <AddRecordModal
                    patientId={patient?.id || ""}
                    form={form}
                    isOpen={isOpen}
                    onClose={handleCloseAddRecordModal}
                  />
                  <Button mr={4} onClick={onOpen}>
                    Add Record
                  </Button>
                </>
              )}
              <Button
                aria-label="Export to CSV"
                leftIcon={<FaDownload />}
                onClick={() =>
                  exportToCSV(
                    rows,
                    [
                      "Date Recorded",
                      ...(form?.fields.map((field) => field.label) ?? []),
                    ],
                    patient?.name ?? "export_data"
                  )
                }
                variant="outline"
              >
                Export to CSV
              </Button>
            </Box>
          )}
        </Flex>
        <Box overflowX={"auto"}>
          <Table variant="simple" size={isAdminView ? "md" : "sm"}>
            <Thead>
              <Tr>
                <Th>Date Recorded</Th>
                {form?.fields.map((field, idx) => {
                  return (
                    <Th
                      key={`form-field-${idx}`}
                      isNumeric={field.responseType === ResponseType.NUMBER}
                    >
                      {field.label}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {rows.map((row, rowIdx) => {
                return (
                  <Tr key={`row-${rowIdx}`}>
                    {row.map((cell, colIdx) => {
                      if (colIdx !== DATE_COL_IDX) {
                        return (
                          <Td key={`row-${rowIdx}-${colIdx}`} isNumeric>
                            {cell}
                          </Td>
                        );
                      }
                      return (
                        <Td key={`row-${rowIdx}-${colIdx}`}>
                          {new Date(cell).toLocaleDateString()}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Container>
    </>
  );
};

export default ClientResponseView;
