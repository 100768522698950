import { ProviderType } from "../types/form";

export const ROUTES = {
  FORMS_LIST: "/forms",
  CLIENTS: "/clients",
  CREATE_FORM: "/create-form",
  RESPONSES: "/responses",
  LOGIN: "/login",
  REGISTER: "/register",
  SETTINGS: "/settings",
  SIGN_UP: "/sign-up",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSCODE: "/reset-passcode",
  NOT_FOUND: "/not-found",
  FORM_RESPONSE: "/form-response",
  FORM: "/form",
  TAGS: "/tags",
  REPORT: "/report",
  EXPERIMENTAL: "/experimental"
};

export const theme = "blue";
export const backgroundColor = "white";

export const messagingApps: ProviderType[] = [
  ProviderType.WHATSAPP,
  ProviderType.TELEGRAM,
  ProviderType.FB_MESSENGER,
];
